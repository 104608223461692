import styled from 'styled-components'
import { Media, mbSize } from '@renderbus/common/theme'

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0 30px 77px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  ${Media.lessThan(Media.small)} {
    padding: 0;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  flex-shrink: 0;
  transform: ${({ curIndex }) => `translateX(calc(-${curIndex} * 100%))`};
  video {
    margin: 7px auto 20px;
    height: 458px;
    width: 786px;
    object-fit: fill;
  }
  ${Media.lessThan(Media.small)} {
    padding: 5px;
    width: 100%;
    text-align: center;
    video {
      height: 42.75vw;
      width: 76vw;
    }
  }
`

export const ArrowButton = styled.div`
  width: 45px;
  height: 45px;
  position: absolute;
  border: 1px solid #8db2fd;
  border-radius: 50%;
  top: 200px;
  display: ${({ disable }) => (disable ? 'none' : 'block')};
  cursor: pointer;
  &:hover {
    border: 1px solid #2a70ff;
  }
  ${Media.lessThan(Media.small)} {
    top: 85px;
    border: none;
    &:hover {
      border: none;
      width: 20px;
      height: 20px;
    }
  }
`

export const ArrowLeftButton = styled(ArrowButton)`
  left: 5px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid #8db2fd;
    top: 14px;
    left: 18px;
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotateZ(45deg);
  }
  &:hover {
    &:after {
      border: 1px solid #2a70ff;
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
  ${Media.lessThan(Media.small)} {
    left: 0;
    &:after {
      left: 10px;
    }
  }
`

export const ArrowRightButton = styled(ArrowButton)`
  right: 19px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid #8db2fd;
    top: 14px;
    right: 18px;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform: rotateZ(45deg);
  }
  &:hover {
    &:after {
      border: 1px solid #2a70ff;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
  ${Media.lessThan(Media.small)} {
    right: 0;
    &:after {
      right: 10px;
    }
  }
`

export const BottomContentWrapper = styled.div`
  display: flex;
  column-gap: 188px;
  > div:nth-child(1) {
    white-space: nowrap;
  }
  p {
    color: #eee;
    font-weight: 500;
    font-size: 18px;
  }
  a {
    color: #348ef5;
  }
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    text-align: left;
    p {
      font-size: ${mbSize(28)};
      margin: 0 ${mbSize(16)};
      line-height: ${mbSize(56)};
    }
  }
`
export const BottomContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
  }
`
export const Interview = styled.div`
  flex-basis: 440px;
  flex-grow: 0;
  margin: 12px 40px 12px 0px;
  span {
    font-size: 18px;
    font-weight: 350;
    color: #fff;
    line-height: 42px;
  }

  .link {
    color: #6295fd;
    cursor: pointer;
  }
  ${Media.lessThan(Media.small)} {
    flex-basis: 0px;
    margin: 0 ${mbSize(16)} ${mbSize(12)} ${mbSize(16)};
    text-align: left;
    span {
      font-size: ${mbSize(28)};
      line-height: ${mbSize(56)};
    }
  }
`
