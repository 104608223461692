import styled, { createGlobalStyle } from 'styled-components'
import Img from 'gatsby-image'
import { Media, typography } from '@renderbus/common/theme'
import transition from '../../../common/theme/transition'
import RoboBlackItalic from '../font/Robo-BlackItalic.ttf'
import RobotoBold from '../font/Roboto-Bold.ttf'
import RobotoRegular from '../font/Roboto-Regular.ttf'

import HeaderLogoImg from '../images/render-contest-2023/header-logo.png'
import MenuIcon from '../images/render-contest-2023/menu-icon.png'
import CloseIcon from '../images/render-contest-2023/close-icon.png'
import TimeBgImg from '../images/render-contest-2023/time_bg@2x.png'
import NextArrowImg from '../images/render-contest-2023/next@2x.png'
import GroupBgImg from '../images/render-contest-2023/zu_bg@2x.png'
import LeftArrowIcon from '../images/render-contest-2023/arrow1@2x.png'
import LeftArrowHoverIcon from '../images/render-contest-2023/arrow1_hover@2x.png'
import RightArrowIcon from '../images/render-contest-2023/arrow2@2x.png'
import RightArrowHoverIcon from '../images/render-contest-2023/arrow2_hover@2x.png'
import UpArrowIcon from '../images/render-contest-2023/arrow_1@2x.png'
import DownArrowIcon from '../images/render-contest-2023/arrow_2@2x.png'
import CheckedIcon from '../images/render-contest-2023/check@2x.png'

export const RCStyle = createGlobalStyle`
  @font-face {
    font-family: 'Robo-BlackItalic';
    src: url(${RoboBlackItalic});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Bold';
    src: url(${RobotoBold});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Regular';
    src: url(${RobotoRegular});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  html {
    background-color: #15145c;
  }
  .hide {
    display: none !important;
  }
  .hideInMobile {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
  .hideInPC {
    ${Media.greaterThan(Media.small)} {
      display: none !important;
    }
  }
`
export const BannerContainer = styled.div`
  padding-top: 60px;
`
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const PageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 73px;
  width: 1200px;
  height: 63px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  border-top: 1px solid #999999;
  color: #b0b6bc;
  img {
    margin: 10px 11px 0 5px;
    transform: translate(0, -50%);
  }
  a:hover {
    color: #5195f5;
  }
  ${Media.lessThan(Media.small)} {
    width: 92vw;
    /* height: 17.33vw; */
    height: auto;
    margin-top: 12.93vw;
    flex-direction: column;
    justify-content: space-around;
    font-size: 2.39vw;
    padding-top: 2.93vw;
    padding-bottom: 5.33vw;
    p {
      margin: auto;
    }
    img {
      margin: 1.6vw 3.33vw 0 5px;
      width: 1.86vw;
    }
  }
`
export const IndexSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`
export const ImgWrapper = styled(IndexSection)`
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: -1;
  &::after {
    content: '';
    width: 100%;
    height: 100px;
    position: absolute;
    top: -3px;
    z-index: 100;
    background: linear-gradient(#15145c, transparent);
    filter: blur(1px);
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const TitleSection = styled(IndexSection)`
  align-items: center;
  color: #ffffff;
  h2 {
    margin: 0;
  }
  > img:first-child {
    width: 233px;
    height: 85px;
    & + h2 {
      position: absolute;
      line-height: 85px;
    }
  }
  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 8.13vw;
    > img:first-child {
      width: 31.06vw;
      height: 11.33vw;
      & + h2 {
        position: absolute;
        line-height: 11.33vw;
      }
    }
    h2 {
      font-size: 3.99vw;
      font-weight: bold;
      line-height: 36px;
    }
  }
`
export const RCWordsSection = styled(TitleSection)`
  margin-top: 100px;
  .top-bg {
    position: absolute;
    top: 115px;
    width: 602px;
  }
  .words-container {
    margin-top: 57px;
    text-align: center;
    z-index: 1;
    .words-group {
      margin-bottom: 28px;
    }
    .word {
      line-height: 28px;
      margin: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 13vw;
    .top-bg {
      position: absolute;
      top: 14.93vw;
      width: 80.26vw;
    }
    .words-container {
      margin-top: 3.74vh;
      text-align: center;
      z-index: 1;
      .words-group {
        margin-bottom: 4.8vw;
      }
      .word {
        font-size: 3.2vw;
        line-height: 4.8vw;
        margin: 0;
      }
    }
  }
`
export const RCResultSection = styled(TitleSection)`
  margin-top: -99vh;
  ${Media.lessThan(Media.small)} {
    width: 92.13vw;
    margin: auto;
  }
`
export const RCDateSection = styled(TitleSection)`
  margin-top: 64px;
  .dates-container {
    display: flex;
    margin-top: 53px;
    font-weight: bold;
    font-family: 'Roboto-Bold';
    span {
      font-style: italic;
      font-size: 18px;
      font-family: none;
    }
    p {
      font-size: 36px;
      margin: 0;
    }
    > div {
      width: 306px;
      height: 233px;
      background: url(${TimeBgImg}) no-repeat;
      background-size: 100%;
      padding: 47px 0 0 50px;
      position: relative;
      &:after {
        content: ' ';
        width: 101px;
        height: 41px;
        top: 82px;
        right: -85px;
        position: absolute;
        background: url(${NextArrowImg});
        background-size: 100%;
      }
    }
    .judge-date {
      margin: 0 80px;
    }
    .result-date {
      &:after {
        display: none;
      }
    }
    ${Media.lessThan(Media.small)} {
      margin-top: 10.79vw;
      span {
        font-style: italic;
        font-size: 3.2vw;
        font-family: none;
        margin-left: 2vw;
      }
      p {
        font-size: 4vw;
        margin: 0;
      }
      > div {
        width: 21.46vw;
        height: max-content;
        padding: unset;
        position: relative;
        background: unset;
        &:after {
          content: ' ';
          width: 13.46vw;
          height: 5.46vw;
          top: 0;
          right: -11.06vw;
          position: absolute;
          background: url(${NextArrowImg});
          background-size: 100%;
        }
      }
      .judge-date {
        margin: 0 13.2vw;
      }
      .result-date {
        &:after {
          display: none;
        }
      }
    }
  }
`
export const RCGroupSection = styled(TitleSection)`
  margin-top: 87px;
  .group-container {
    display: flex;
    margin-top: 78px;
    height: 233px;
    width: 1170px;
    justify-content: space-between;
    .group {
      background: url(${GroupBgImg});
      background-size: 100%;
      width: 522px;
      height: 233px;
      display: flex;
      span {
        font-size: 24px;
        font-weight: bold;
      }
      p {
        margin-top: 20px;
        width: max-content;
        font-weight: 400;
      }
      img {
        &.professional {
          width: 79px;
          height: 80px;
          margin: 41px 0 0 50px;
          + div {
            padding: 45px 0 0 41px;
          }
        }
        &.student {
          width: 81px;
          height: 81px;
          margin: 41px 0 0 60px;
          + div {
            padding: 46px 0 0 39px;
          }
        }
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 10.66vw;
    .group-container {
      display: flex;
      flex-direction: column;
      margin-top: 6.07vh;
      padding-left: 12.8vw;
      height: unset;
      width: 100vw;
      .group {
        background: unset;
        width: max-content;
        height: unset;
        span {
          font-size: 3.99vw;
          font-weight: bold;
        }
        p {
          margin-top: 1.59vw;
          margin-bottom: unset;
          font-size: 3.06vw;
          width: unset;
        }
        img {
          &.professional {
            width: 10.53vw;
            height: 10.66vw;
            margin: unset;
            + div {
              padding: unset;
              margin-left: 6.8vw;
              margin-bottom: 10.79vw;
            }
          }
          &.student {
            width: 10.8vw;
            height: 10.8vw;
            margin: unset;
            + div {
              padding: unset;
              margin-left: 6.53vw;
              margin-bottom: 10.66vw;
            }
          }
        }
      }
    }
  }
`
export const AwardType = styled.span`
  position: relative;
  left: -10px;
  width: 161px;
  margin-bottom: 10px;
  height: 41px;
  line-height: 41px;
  font-size: ${p => (p.isActive ? '18px' : '16px')};
  font-weight: ${p => (p.isActive ? 'bold' : '400')};
  color: ${p => (p.isActive ? '#FFFFFF' : '#DDDDDD')};
  cursor: pointer;
  text-align: center;
  user-select: none;
  border: ${p => p.isActive && '1px solid transparent'};
  border-right: none;
  border-image: ${p =>
    p.isActive ? 'linear-gradient(90deg, #5195F5, transparent) 10 10' : 'unset'};
  background: ${p =>
    p.isActive ? 'linear-gradient(90deg, #425FCB 0%, transparent 100%)' : 'unset'};
  ${Media.lessThan(Media.small)} {
    all: unset;
    height: 8.13vw;
    min-width: max-content;
    line-height: 8.13vw;
    font-size: 2.66vw;
    text-align: center;
    user-select: none;
    color: ${p => (p.isActive ? '#FFFFFF' : '#D9E8F8')};
    font-weight: ${p => (p.isActive ? 'bold' : '400')};
    border-image: ${p => (p.isActive ? 'linear-gradient(90deg, #5195F5, #283C78) 10 10' : 'unset')};
    background: ${p =>
      p.isActive ? 'linear-gradient(0deg, rgba(40,60,120,0) 0%, #425FCB 100%)' : 'unset'};
    border-bottom: none;
  }
`
export const RCAwardSection = styled(TitleSection)`
  margin-top: 51px;
  .award-container {
    display: flex;
    margin-top: 60px;
    padding-right: 30px;
    width: 1181px;
    min-height: 321px;
    height: max-content;
    border-image: linear-gradient(0deg, #5195f5, #283c78) 10 10;
    background: linear-gradient(0deg, #425fcb 0%, rgba(40, 60, 120, 0.1) 100%);
  }
  .award-type {
    display: flex;
    flex-direction: column;
    margin-top: 41px;
    margin-right: 29px;
  }
  .award {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 321px;
    margin-right: 9px;
    position: relative;
    margin-bottom: 35px;
    .title {
      width: 321px;
      height: 101px;
      line-height: 101px;
      text-align: center;
      border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
      background: linear-gradient(0deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
      font-size: 20px;
      font-weight: bold;
    }
    &.echo {
      width: 641px;
      margin-bottom: 104px;
      .title {
        width: 641px;
        line-height: 75px;
        margin-bottom: 22px;
      }
      .explain {
        width: 540px;
        height: 29px;
        top: 75px;
      }
      .other {
        padding: 0 66px 0 56px;
      }
    }
    &.style-3d {
      width: 641px;
      margin-bottom: 70px;
      .title {
        width: 641px;
        line-height: 75px;
        margin-bottom: 54px;
      }
      .explain {
        width: 540px;
        height: 70px;
        padding: 0 20px;
        top: 65px;
      }
      .other {
        padding: 0 66px 0 56px;
      }
    }
    &.popular {
      width: 641px;
      margin-bottom: 70px;
      .title {
        width: 641px;
        line-height: 75px;
        margin-bottom: 20px;
      }
      .explain {
        width: 540px;
        height: 29px;
        top: 75px;
      }
      .other {
        padding: 0 55px 0 51px;
      }
    }
    &.more-honor {
      width: 641px;
      margin-bottom: 155px;
      .title {
        width: 641px;
        line-height: 75px;
      }
      .other {
        padding: 0 0 0 71px;
      }
    }
    .explain {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      top: 65px;
      font-weight: 400;
      font-size: 14px;
      background: rgba(66, 95, 203, 0.3);
      border-radius: 15px;
      line-height: 24px;
    }
    .value {
      font-family: 'Roboto-Bold';
      width: 240px;
      height: 29px;
      line-height: 29px;
      background: rgba(66, 95, 203, 0.3);
      border-radius: 14px;
      text-align: center;
      margin-top: -14px;
      margin-bottom: 25px;
    }
    .other {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      width: 100%;
      padding: 0 12px 0 10px;
      text-align: start;
    }
  }

  ${Media.lessThan(Media.small)} {
    margin-top: 3.82vh;
    .award-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
      padding: unset;
      width: 92.13vw;
      min-height: 321px;
      height: max-content;
      border-image: linear-gradient(0deg, #5195f5, #283c78) 10 10;
      background: linear-gradient(0deg, #425fcb 0%, rgba(40, 60, 120, 0.1) 100%);
    }
    .award-type {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: unset;
      width: 100%;
      margin-bottom: 3.86vw;
    }
    .award {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 82.8vw;
      margin: unset;
      padding-bottom: 2.66vw;
      position: relative;
      margin-bottom: 8.53vw;
      .title {
        width: 82.8vw;
        height: 20vw;
        text-align: center;
        border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
        background: linear-gradient(0deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
        font-size: 3.86vw;
        font-weight: bold;
        line-height: 13vw;
      }
      &.echo {
        width: 82.8vw;
        margin-bottom: unset;
        .title {
          width: 82.8vw;
          line-height: 75px;
          margin-bottom: 22px;
        }
        .explain {
          width: 69.88vw;
          height: 5.33vw;
          top: 14.67vw;
        }
        .other {
          padding: 0 66px 0 56px;
        }
      }
      &.style-3d {
        width: 82.8vw;
        margin-bottom: 8.53vw;
        .title {
          width: 82.8vw;
        }
        .value {
          margin-top: 0;
          width: 45.33vw;
          margin-bottom: 4.56vw;
        }
        .other {
          padding: 0 8.4vw 0 6.93vw;
        }
        .explain {
          width: 69.88vw;
          height: 5.33vw;
          top: 14.67vw;
          padding: 5vw;
          line-height: unset;
        }
      }
      &.popular {
        width: 82.8vw;
        margin-bottom: 8.53vw;
        .title {
          width: 82.8vw;
        }
        .explain {
          width: 69.88vw;
          height: 5.33vw;
          top: 14.67vw;
        }
        .other {
          padding: 0 8.4vw 0 6.93vw;
        }
      }
      &.more-honor {
        width: 82.8vw;
        margin-bottom: 8.53vw;
        .title {
          width: 82.8vw;
        }
        .other {
          padding: 0 8.4vw 0 6.93vw;
          margin-bottom: unset;
        }
      }
      .explain {
        width: max-content;
        position: absolute;
        top: 65px;
        font-weight: 400;
        font-size: 1.86vw;
      }
      .value {
        font-family: 'Roboto-Bold';
        width: 45.33vw;
        height: 5.33vw;
        line-height: 5.33vw;
        background: rgba(66, 95, 203, 0.3);
        border-radius: 14px;
        text-align: center;
        margin-top: -5.83vw;
        margin-bottom: 4.56vw;
        font-size: 2.66vw;
      }
      .other {
        font-size: 2.66vw;
        font-weight: 400;
        line-height: 3.99vw;
        text-align: start;
        padding: 0 8.4vw 0 6.93vw;
      }
    }
  }
`
export const LeftArrow = styled.button`
  background: url(${LeftArrowIcon}) no-repeat;
  background-size: 100%;
  width: 40px;
  height: 80px;
  cursor: pointer;
  border: unset;
  &:hover {
    background: url(${LeftArrowHoverIcon}) no-repeat;
    background-size: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: 5.33vw;
    height: 10.66vw;
  }
`
export const RightArrow = styled.button`
  background: url(${RightArrowIcon}) no-repeat;
  background-size: 100%;
  width: 40px;
  height: 80px;
  cursor: pointer;
  border: unset;
  &:hover {
    background: url(${RightArrowHoverIcon}) no-repeat;
    background-size: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: 5.33vw;
    height: 10.66vw;
  }
`
export const Profile = styled.div`
  z-index: 9;
  height: 100%;
  width: ${p =>
    !p.isActive ? (p.width ? p.width : '240px') : p.hoverWidth ? p.hoverWidth : '340px'};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${p =>
    p.isActive ? `url(${p.imgHover})} no-repeat` : p.img ? `url(${p.img}) no-repeat` : '#425FCB'};
  background-size: 100%;
  border-radius: 30px 10px 10px 10px;
  font-size: 14px;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &.expand {
    .short {
      display: ${p => p.isActive && 'none'};
    }
    .total {
      display: ${p => p.isActive && 'unset !important'};
    }
  }
  .name {
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    margin: 0 0 0 20px;
  }
  .intros {
    min-height: 50px;
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    p {
      margin: unset;
      font-size: 14px;
      font-weight: 400;
      margin-left: 21px;
      margin-right: 21px;
      line-height: 24px;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${p =>
      p.isActive ? (p.hoverWidth ? p.hoverWidth : '45.33vw') : p.mbWidth ? p.mbWidth : '32vw'};
    .name {
      font-size: 3.19vw;
      font-weight: bold;
      margin: 0 0 1.46vw 3.86vw;
    }
    .intros {
      min-height: 6.66vw;
      margin-bottom: unset;
      p {
        margin: unset;
        font-size: 1.86vw;
        font-weight: 400;
        margin-left: 3.86vw;
        margin-right: 1vw;
        line-height: 3.19vw;
      }
    }
  }
`
export const ProfileContainer = styled.div`
  display: flex;
  width: ${p => (p.width ? p.width : '1119px')};
  height: ${p => (p.height ? p.height : '400px')};
  position: relative;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    width: 100vw;
    padding: ${p => (p.mbPadding ? p.mbPadding : '0 7.86vw 0 12.13vw')};
    height: ${p => (p.mbHeight ? p.mbHeight : '53.33vw')};
  }
`
export const RCJudgesSection = styled(TitleSection)`
  margin-top: 100px;
  ${LeftArrow} {
    position: absolute;
    left: -41px;
    top: 50%;
    transform: translate(-100%, -50%);
  }
  ${RightArrow} {
    position: absolute;
    right: -41px;
    top: 50%;
    transform: translate(100%, -50%);
  }
  ${ProfileContainer} > span {
    position: absolute;
    top: -41px;
    left: -30px;
    transform: translate(0, -100%);
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
  }
  .main {
    margin-top: 129px;
  }
  .expert {
    margin-top: 168px;
    &.hideInMobile {
      margin-top: 28px;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 10.53vw;
    ${LeftArrow} {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    ${RightArrow} {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    ${ProfileContainer} > span {
      left: 8.13vw;
      top: -5.59vw;
      transform: translate(0, -100%);
      font-size: 3.86vw;
      font-weight: bold;
      line-height: 3.86vw;
    }
    .main {
      margin-top: 20.13vw;
    }
    .expert {
      margin-top: 17.73vw;
    }
  }
`
export const LeftTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: max-content;
  margin-top: 35px;
  margin-bottom: 164px;
  user-select: none;
`
export const Tag = styled.span`
  text-align: center;
  position: relative;
  left: ${p => p.left || '-10px'};
  cursor: pointer;
  border: ${p => (p.isActive ? '1px solid transparent' : 'unset')};
  border-right: none;
  border-image: ${p =>
    p.isActive ? 'linear-gradient(90deg, #5195F5, transparent) 10 10' : 'unset'};
  background: ${p =>
    p.isActive ? 'linear-gradient(90deg, #425FCB 0%, transparent 100%)' : 'unset'};
  font-weight: ${p => (p.isActive ? 'bold' : '400')};
  color: ${p => (p.isActive ? '#FFFFFF' : '#DDDDDD')};
  height: ${p => (p.isActive ? '41px' : '36px')};
  width: 181px;
  line-height: ${p => (p.isActive ? '41px' : '36px')};
  font-size: ${p => (p.isActive ? '18px' : '16px')};
  ${Media.lessThan(Media.small)} {
    height: ${p => (p.isActive ? '8.13vw' : '4.79vw')};
    width: 24.13vw;
    line-height: ${p => (p.isActive ? '8.13vw' : '4.79vw')};
    font-size: ${p => (p.isActive ? '3.19vw' : '2.66vw')};
  }
`
export const RCContentSection = styled(TitleSection)`
  margin-top: 64px;
  .content {
    display: flex;
    width: 1180px;
    min-height: 400px;
    margin-top: 64px;
  }
  .other {
    margin-top: 80px;
    width: 1181px;
    height: 400px;
    display: flex;
    border: 1px solid;
    border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
    background: linear-gradient(0deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
    ${LeftTagsContainer} {
      ${'' /* margin-top: 2.79vw;
      margin-bottom: 15.59vw; */}
    }
    .standar-container {
      display: flex;
      flex-wrap: wrap;
      padding: 36px 0 96px 70px;
      .standar {
        width: 50%;
        height: max-content;
        p {
          margin: 30px 0 14px 0;
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          line-height: 36px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
      }
    }
    .support-container {
      display: flex;
      flex-wrap: wrap;
      margin-left: 71px;
      padding: 52px 0 66px 0;
      .support {
        width: 40%;
        margin-right: 10%;
        .title {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          line-height: 20px;
          margin: 0 0 14px 0;
        }
        .subTitle {
          margin: 12px 0 27px 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
        }
        a {
          width: 160px;
          height: 40px;
          background: linear-gradient(90deg, #00fdb0 0%, #f7fc2a 100%);
          border-radius: 20px;
          font-size: 14px;
          font-weight: bold;
          color: #050706;
          line-height: 40px;
          display: inline-block;
          text-align: center;
          margin-bottom: 54px;
          &.download {
            margin-right: 20px;
            img {
              margin-right: 10px;
              width: 15px;
              height: 17px;
            }
          }
          &.go {
            img {
              margin-left: 7px;
              width: 17px;
              height: 14px;
            }
          }
          &.usage {
            margin-left: 20px;
          }
          &.echo {
            width: 180px;
          }
        }
      }
    }
    .exchange-container {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      padding: 49px 0 0 70px;
      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        margin: 0 0 18px 0;
      }
      span,
      p {
        line-height: 30px;
        margin: unset;
      }
      div {
        display: flex;
        align-items: center;
        margin-top: 21px;
        img {
          width: 120px;
          height: 120px;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 25px;
          p {
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
    .notice-container {
      display: flex;
      flex-direction: column;
      margin-left: 67px;
      margin-right: 98px;
      p {
        font-weight: bold;
        font-size: 20px;
        margin: 51px 0 19px 0;
      }
      span {
        font-size: 14px;
        line-height: 30px;
      }
    }
    .profit-container {
      display: flex;
      flex-direction: column;
      margin-left: 67px;
      margin-right: 98px;
      p {
        font-weight: bold;
        font-size: 20px;
        margin: 52px 0 19px 0;
      }
      span {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
  .video-container {
    width: 580px;
    height: 337px;
    padding: 13px 10px;
    border-image: linear-gradient(0deg, #5195f5, #283c78) 10 10;
    background: linear-gradient(0deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
    position: relative;
    cursor: pointer;
    img {
      width: 90px;
      height: 90px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    width: 560px;
    margin-left: 41px;
    p {
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      line-height: 36px;
      margin: 16px 0 29px 0;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
    a {
      width: 200px;
      height: 40px;
      background: linear-gradient(90deg, #f7fc2a 0%, #3fffc6 100%);
      border-radius: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #050706;
      line-height: 40px;
      text-align: center;
      margin-top: 49px;
      &:hover {
        background: linear-gradient(0deg, #00fdb0 0%, #f7fc2a 100%);
      }
      img {
        display: inline-block;
        width: 17px;
        height: 19px;
        margin-right: 11px;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 10.66vw;
    .content {
      flex-direction: column;
      width: 82.53vw;
      min-height: 311px;
      margin-top: 10.66vw;
    }
    .other {
      margin-top: 7.86vw;
      width: 89.46vw;
      height: min-content;
      padding: 2vw 2vw 4vw 0;
      ${LeftTagsContainer} {
        margin-top: 2.79vw;
        margin-bottom: unset;
        height: 40vw;
      }
      .standar-container {
        padding: 1.74vw 5vw 0 0;
        .standar {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 3.19vw;
          p {
            margin: 0 0 1.86vw 0;
            font-size: 3.19vw;
            line-height: 3.99vw;
          }
          span {
            font-size: 2.66vw;
            line-height: 3.99vw;
          }
        }
      }
      .support-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        padding: 1.74vw 5vw 0 0;
        .support {
          width: 100%;
          margin-right: unset;
          margin-bottom: 2vw;
          .title {
            font-size: 3.19vw;
            line-height: 3.99vw;
            margin: 0 0 1.86vw 0;
          }
          .subTitle {
            margin: 0;
            font-size: 2.66vw;
            line-height: 3.99vw;
          }
          a {
            width: 20vw;
            height: 5vw;
            font-size: 1.86vw;
            line-height: 5vw;
            display: inline-block;
            text-align: center;
            margin-bottom: 1vw;
            &.download {
              margin-right: 2vw;
              img {
                margin-right: 1vw;
                width: 2vw;
                height: 2.26vw;
              }
            }
            &.go {
              img {
                margin-left: 1vw;
                width: 2.26vw;
                height: 1.86vw;
              }
            }
            &.echo {
              width: 22.5vw;
            }
            &.usage {
              margin-left: 2vw;
            }
          }
        }
      }
      .exchange-container {
        font-size: 2.66vw;
        padding: 3.48vw 0 0 0;
        .title {
          font-size: 3.19vw;
          line-height: 3.99vw;
          margin: 0 0 2.39vw 0;
        }
        span,
        p {
          line-height: 3.99vw;
          margin: unset;
        }
        div {
          display: flex;
          align-items: center;
          margin-top: 2.79vw;
          img {
            width: 16vw;
            height: 16vw;
          }
          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 3.33vw;
            p {
              span {
                font-weight: bold;
              }
            }
          }
        }
      }
      .notice-container {
        margin-left: 0;
        margin-right: 2vw;
        p {
          font-size: 3.19vw;
          line-height: 3.99vw;
          margin: 1.74vw 0 1vw 0;
        }
        span {
          font-size: 2.66vw;
          line-height: 3.99vw;
        }
      }
      .profit-container {
        margin-left: 0;
        margin-right: 2vw;
        p {
          font-size: 3.19vw;
          line-height: 3.99vw;
          margin: 1.74vw 0 1vw 0;
        }
        span {
          font-size: 2.66vw;
          line-height: 3.99vw;
        }
      }
    }
    .video-container {
      width: 80.13vw;
      height: 48.13vw;
      padding: 3.33vw 2.8vw;
      border-image: linear-gradient(0deg, #5195f5, #283c78) 10 10;
      background: linear-gradient(0deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
      position: relative;
      cursor: pointer;
      img {
        width: 12vw;
        height: 12vw;
      }
    }
    .text-container {
      width: 82.53vw;
      margin: unset;
      p {
        font-size: 3.99vw;
        font-weight: bold;
        color: #ffffff;
        line-height: 4.79vw;
        margin: 5.33vw 0 3.86vw 0;
      }
      span {
        font-size: 2.66vw;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.79vw;
      }
      a {
        width: 40vw;
        height: 8vw;
        background: linear-gradient(90deg, #f7fc2a 0%, #3fffc6 100%);
        font-size: 3.59vw;
        font-weight: bold;
        color: #050706;
        line-height: 8vw;
        text-align: center;
        margin-top: 7.59vw;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        img {
          display: inline-block;
          width: 3.13vw;
          height: 3.8vw;
          margin-right: 3.33vw;
        }
      }
    }
  }
`
export const RCDesignSection = styled(TitleSection)`
  margin-top: 79px;
  .design-container {
    display: flex;
    margin-top: 64px;
    width: 1181px;
    min-height: 361px;
    border: 1px solid;
    border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
    background: linear-gradient(0deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
    ${LeftTagsContainer} {
      margin-top: 61px;
      margin-bottom: 160px;
    }
    .content {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      margin-right: 143px;
      padding-bottom: 38px;
      p {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        margin: 60px 0 19px 0;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 10.39vw;
    .design-container {
      display: flex;
      margin-top: 7.99vw;
      width: 89.46vw;
      min-height: unset;
      height: max-content;
      border: 1px solid;
      border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
      background: linear-gradient(0deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
      ${LeftTagsContainer} {
        margin-top: 8.13vw;
        margin-bottom: unset;
        height: fit-content;
        ${Tag} {
          margin-bottom: 2vw;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 2.8vw;
        p {
          font-size: 2.66vw;
          font-weight: bold;
          line-height: 2.66vw;
          margin: 7.99vw 0 2.39vw 0;
        }
        span {
          font-size: 2.66vw;
          line-height: 4.79vw;
        }
      }
    }
  }
`
export const RCQASection = styled(TitleSection)`
  margin-top: 79px;
  .q-container {
    position: relative;
    width: 1201px;
    height: 701px;
    margin-top: 50px;
    padding: 40px;
    overflow-y: scroll;
    border: 1px solid;
    border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
    background: linear-gradient(0deg, #15145c 0%, rgba(75, 40, 110, 0.2) 100%);
    .ps__rail-x {
      display: none;
    }
    .ps__rail-y {
      width: 10px;
      padding-left: 10px !important;
      background: unset;
      .ps__thumb-y {
        width: 10px;
        padding-left: 10px !important;
        background-color: #5195f5;
      }
    }
    div {
      height: 81px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 23px;
      margin-bottom: 9px;
      p {
        margin: unset;
        font-size: 14px;
        line-height: 24px;
        margin-right: 28px;
      }
    }
    .odd {
      border: 1px solid;
      border-image: linear-gradient(90deg, #5195f5, transparent) 10 10;
      background: linear-gradient(90deg, #425fcb 0%, rgba(40, 60, 120, 0) 100%);
      border-right: none;
    }
    .even {
      border: 1px solid;
      border-image: linear-gradient(90deg, transparent, #5195f5) 10 10;
      background: linear-gradient(90deg, rgba(40, 60, 120, 0) 0%, #425fcb 100%);
      border-left: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 10.53vw;
    .q-container {
      position: relative;
      width: 92.13vw;
      height: 128.13vw;
      margin-top: 7.99vw;
      padding: 5.33vw 4vw 5.33vw 2.66vw;
      overflow: scroll;
      border: 1px solid;
      border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
      background: linear-gradient(0deg, #15145c 0%, rgba(75, 40, 110, 0.2) 100%);
      .ps__rail-x {
        display: none;
      }
      .ps__rail-y {
        width: 1.33vw;
        padding-left: 1.33vw !important;
        background: unset;
        .ps__thumb-y {
          width: 1.33vw;
          padding-left: 1.33vw !important;
          background-color: #5195f5;
        }
      }
      div {
        min-height: 13.46vw;
        height: max-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2.66vw;
        padding-top: 2.66vw;
        padding-bottom: 1.59vw;
        margin-bottom: 2.53vw;
        p {
          margin: unset;
          font-size: 2.39vw;
          line-height: 3.39vw;
          margin-right: 2.66vw;
        }
      }
    }
  }
`
export const RCFormSection = styled(TitleSection)`
  margin: 59px 0 0 0;
  ${Media.lessThan(Media.small)} {
    margin: 7.86vw 0 0 0;
  }
`
export const RCSignFormContainer = styled.form`
  width: 1141px;
  border: 1px solid;
  font-size: 14px;
  border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
  background: linear-gradient(0deg, #15145c 0%, rgba(75, 40, 110, 0.2) 100%);
  display: flex;
  flex-direction: column;
  aling-items: center;
  padding: 60px 0 78px 0;
  h2 {
    text-align: center;
    margin-bottom: 59px;
  }
  .qr-container {
    margin-top: 60px;
    display: flex;
    img {
      width: 120px;
      height: 120px;
      margin-left: 294px;
    }
    div {
      margin-left: 55px;
      p {
        margin: unset;
        font-size: 14px;
        color: #dddddd;
        line-height: 24px;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 94.8vw;
    padding: 7.99vw 0 10.26vw 0;
    h2 {
      margin: 0 0 7.99vw 0;
      font-size: 3.99vw;
      line-height: 3.99vw;
    }
    .qr-container {
      margin-top: 8.13vw;
      img {
        width: 19.19vw;
        height: 19.19vw;
        margin-left: 6vw;
      }
      div {
        margin-left: 4vw;
        p {
          font-size: 2.39vw;
          line-height: 3.39vw;
        }
      }
    }
  }
`
export const RCSignForm = styled.div``
export const RCInput = styled.input`
  width: ${p => (p.width ? `${p.width}px` : '201px')};
  height: 31px;
  border: 1px solid #3e5abe;
  border-radius: 4px;
  outline-style: none;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:focus {
    border: 1px solid #5195f5;
  }
`
export const StudentCardContainer = styled.div`
  margin-left: 270px;
  position: relative;
  display: flex;
`
export const RCRadio = styled.input`
  cursor: pointer;
  opacity: 0;
  margin-left: ${p => `${p.left}px`};
  + span {
    position: relative;
    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -33px;
      top: 50%;
      transform: translate(0, -50%);
      width: 22px;
      height: 22px;
      border: 2px solid #5195f5;
      border-radius: 50%;
      background: transparent;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
  &:checked {
    + span::after {
      background: #5195f5;
    }
  }
  ${Media.lessThan(Media.small)} {
    + span {
      margin-left: 3.46vw !important;
    }
  }
`
export const RCRadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  margin-left: ${p => `${p.left}px`};
  div {
    width: 50%;
    position: relative;
    span {
      margin-left: 15px;
    }
    ${RCInput} {
      position: absolute;
      border: none;
      border-bottom: 1px solid #999999;
      border-radius: unset;
    }
  }
  > ${RCRadio} {
    + span {
      margin-left: 14px;
      &::before {
        left: -32px;
      }
      &::after {
        left: -27px;
      }
    }
  }
`
export const RCOption = styled.span`
  cursor: pointer;
  color: ${p => (p.isActive ? '#5195F5' : '#DDDDDD')};
  background: ${p => p.isActive && '#2F323E'};
  line-height: 30px;
  font-size: 14px;
  padding-left: 74px;
  &::before {
    content: 'o ';
  }
  &:hover {
    background: #2f323e;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 2.39vw;
    line-height: 3.99vw;
    padding-left: 9.86vw;
  }
`
export const RCOptionContainer = styled.div`
  display: ${p => (p.isActive ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: #252a3a;
  width: 100%;
  max-height: 280px;
  overflow: hidden;
  z-index: 5;
  .ps__rail-y {
    width: 10px;
    padding-left: 10px !important;
    background: transparent !important;
    .ps__thumb-y {
      width: 10px;
      padding-left: 10px !important;
      background-color: #666666;
    }
  }
  ${Media.lessThan(Media.small)} {
    max-height: 37.33vw;
  }
`
export const RCSelectIcon = styled.div`
  background: ${p =>
    p.isActive ? `url(${UpArrowIcon}) no-repeat` : `url(${DownArrowIcon}) no-repeat`};
  background-size: 100%;
  position: absolute;
  width: 18px;
  height: 11px;
  left: 16px;
  top: 50%;
  transform: translate(0, -50%);
  ${Media.lessThan(Media.small)} {
    width: 3vw;
    height: 1.83vw;
    left: 1.86vw;
  }
`
export const RCSelect = styled(RCInput)`
  position: relative;
  outline-style: none;
  width: 521px;
  height: 31px;
  line-height: 100%;
  border: 1px solid #3e5abe;
  border-radius: 4px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: unset;
  padding-left: 48px;
  &:focus {
    border: 1px solid #5195f5;
  }
  ${Media.lessThan(Media.small)} {
    width: 84.13vw;
    height: 6.79vw;
    font-size: 2.39vw;
    padding-left: 7.2vw;
  }
`
export const RCSelectContainer = styled.div`
  position: relative;
  display: inline-block;
`
export const RCLabel = styled.span`
  font-size: 14px;
  height: 100%;
  margin-left: ${p => `${p.left}px`};
  margin-right: ${p => `${p.right}px`};
  margin-top: ${p => `${p.top}px`};
  ${Media.lessThan(Media.small)} {
    all: unset;
    font-size: 2.66vw;
    + ${RCInput}, + ${RCSelect} {
      font-size: 2.39vw;
      height: 6.79vw;
    }
    &.name {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
      }
    }
    &.phone {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.email {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.wechat {
      margin: 0 3.9vw 0 4.5vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.group {
      margin: 0 5vw 0 8vw;
      margin-top: 4.79vw;
      + ${RCRadioContainer} {
        all: unset;
        font-size: 2.66vw;
        margin-top: 4.79vw;
        display: flex;
        span.expert {
          margin-right: 9.86vw;
          + input {
            margin: 0;
          }
        }
      }
    }
    &.organization {
      margin: 0 0 0 5.33vw;
      + input {
        width: 84.13vw;
        margin-left: 5.33vw;
        margin-top: 2.53vw;
      }
    }
    &.position {
      margin: 0 0 2.53vw 5.33vw;
      + ${RCSelectContainer} {
        width: 84.13vw;
        margin-left: 5.33vw;
        margin-top: 2.53vw;
      }
    }
    &.business {
      margin: 0 0 2.53vw 5.33vw;
      + ${RCSelectContainer} {
        width: 84.13vw;
        margin-left: 5.33vw;
        margin-top: 2.53vw;
      }
    }
    &.school {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.major {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.info-from {
      margin: 0 0 0 5.33vw;
      + ${RCRadioContainer} {
        margin: unset;
        padding-left: 7.06vw;
        width: 92.94vw;
        font-size: 2.39vw;
        > div {
          width: 35vw;
        }
        span {
          &::before {
          }
          &::after {
          }
        }
        .other-input {
          width: 26.8vw;
        }
      }
    }
  }
`
export const RCCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: ${p => !p.checked && '2px solid #3E5ABE'};
  border-radius: 2px;
  background: ${p => (p.checked ? `url(${CheckedIcon}) no-repeat` : ``)};
  background-size: 100%;
`
export const RCAgreements = styled.div`
  margin-left: 270px;
  width: 644px;
  ${RCCheckbox} {
    position: relative;
    line-height: 24px;
    bottom: -3px;
  }
  span {
    line-height: 24px;
    font-weight: 400;
    color: #dddddd;
    font-size: 14px;
    margin-left: 8px;
  }
  ${Media.lessThan(Media.small)} {
    margin-left: 5.46vw;
    width: 81.46vw;
    ${RCCheckbox} {
      position: relative;
      line-height: 3.86vw;
      bottom: -3px;
      width: 3.86vw;
      height: 3.86vw;
    }
    span {
      line-height: 3.99vw;
      color: #dddddd;
      font-size: 2.39vw;
      margin-left: 1vw;
    }
  }
`
export const RCUploadContainer = styled.div`
  display: inline-block;
  width: 301px;
  height: 61px;
  background: #3e5abe;
  border: 1px solid #5195f5;
  border-radius: 5px;
  position: relative;
  img.upload {
    width: 30px;
    height: 30px;
    margin-left: 19px;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  p {
    margin: 11px 0 0 69px;
    line-height: 24px;
    font-weight: bold;
    font-size: 16px;
    span {
      color: #f7fc2a;
      cursor: pointer;
    }
    &.tip {
      margin: 0 0 0 63px;
      font-weight: 400;
      font-size: 12px;
    }
  }
  img {
    &.result {
      position: absolute;
      right: -35px;
      top: 0;
      width: 24px;
      height: 24px;
    }
    + span {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      position: absolute;
      top: 1px;
      right: -44px;
      transform: translate(100%, 0);
      &.success {
        color: #5195f5;
      }
      &.fail {
        color: #f54e5e;
      }
    }
  }
`
export const RCButton = styled.button`
  margin-left: 50%;
  transform: translate(-50%);
  width: 160px;
  height: 40px;
  background: #3e5abe;
  background: ${p => (p.disabled ? '#3E5ABE' : 'linear-gradient(90deg, #00fdb0 0%, #f7fc2a 100%)')};
  border-color: transparent;
  border-radius: 20px;
  font-weight: bold;
  color: #050706;
  line-height: 24px;
  cursor: pointer;
  margin-top: 40px;
  &:hover {
    background: ${p => !p.disabled && 'linear-gradient(0deg, #00fdb0 0%, #f7fc2a 100%)'};
  }
  ${Media.lessThan(Media.small)} {
    width: 32vw;
    height: 8vw;
    font-size: 3.19vw;
    line-height: 8vw;
    padding: unset;
    margin-top: 8.93vw;
  }
`
export const Billboard2 = styled(Img)`
  height: auto;
  max-height: 100vh;
`
export const RCHeader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 74px;
  max-width: 1920px;
  user-select: none;
  z-index: 10;
  ${Media.lessThan(Media.small)} {
    background: #252a3a;
    position: fixed;
    top: 0px;
    width: 100vw;
    max-width: 750px;
    height: 12vw;
  }
`
export const RCHeaderContainer = styled.div`
  position: absolute;
  top: 0;
`
export const HeaderLogo = styled.div`
  background: url(${HeaderLogoImg}) no-repeat;
  background-size: 100%;
  min-width: 142px;
  min-height: 48px;
  margin-left: 18.8vw;
  ${Media.lessThan(Media.small)} {
    min-width: 22.83vw;
    min-height: 6.8vw;
    margin-left: 3.46vw;
  }
`
export const HeaderItem = styled.a`
  margin-left: ${p => p.left};
  color: #fff;
  min-width: 64px;
  max-height: 16px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const SignButton = styled.a`
  color: #000;
  font-size: 18px;
  font-weight: bold;
  color: #050706;
  min-width: 140px;
  max-height: 40px;
  line-height: 40px;
  text-align: center;
  background: linear-gradient(90deg, #00fdb0 0%, #f7fc2a 100%);
  border-radius: 20px;
  margin-left: 3.95vw;
  &:hover {
    background: linear-gradient(0deg, #00fdb0 0%, #f7fc2a 100%);
  }
  ${'' /* ${({disabled}) => disabled && css`
    background: #5195f5;
    &:hover {
      background: #5195f5;
    }
  `} */}
  ${Media.lessThan(Media.small)} {
    margin-left: 28.43vw;
    min-width: 28vw;
    max-height: 8vw;
    line-height: 8vw;
    font-size: 3.2vw;
    border-radius: 3vw;
  }
`
export const NavigateMenuIcon = styled.div`
  width: ${p => (p.isActive ? '4vw' : '3.2vw')};
  height: 3.2vw;
  margin-left: ${p => (p.isActive ? '8vw' : '6.26vw')};
  transition: ${transition('transform')};
  background: ${p => (p.isActive ? `url(${MenuIcon})` : `url(${CloseIcon})`)};
  background-size: 100%;
  ${Media.greaterThan(Media.small)} {
    display: none;
  }
`
export const NavigateMenuItem = styled.a`
  color: ${p => (p.isActive ? '#050706' : '#FFFFFF')};
  font-weight: bold;
  border-radius: 4px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 6px;
  text-align: center;
  background: ${p => (p.isActive ? `linear-gradient(90deg, #00FDB0 0%, #F7FC2A 100%)` : 'unset')};
  ${Media.lessThan(Media.small)} {
    width: 26.66vw;
    height: 8vw;
    line-height: 8vw;
    margin-bottom: 2.66vw;
  }
`
export const NavigateMenu = styled.div`
  position: fixed;
  user-select: none;
  display: ${p => (p.showPCMenu ? 'flex' : 'none')};
  left: 1.04vw;
  top: 50vh;
  transform: translate(0, -50%);
  width: 140px;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #2b2662 0%, #425fcb 100%);
  border-radius: 10px;
  z-index: 1;
  padding: 20px 0;
  ${Media.lessThan(Media.small)} {
    display: ${p => (p.isActive ? 'flex' : 'none')};
    position: absolute;
    width: 32vw;
    padding: 5.33vw 0;
    left: 100vw;
    top: 0;
    transform: translate(-100%, 12vw);
  }
  ${NavigateMenuItem}:last-child {
    background: unset;
    color: #ffffff;
    margin-bottom: unset;
    font-weight: unset;
    line-height: ${typography.textSmall};
    height: ${typography.textSmall};
    font-size: ${typography.textSmall};
    margin-top: 5px;
    opacity: 0.9;
    ${Media.lessThan(Media.small)} {
      margin-top: 3vw;
    }
  }
  ${SignButton} {
    margin: 15px 0 22px 0;
    width: 120px;
    min-width: unset;
    font-size: ${typography.text};
    ${Media.lessThan(Media.small)} {
      display: none;
    }
  }
`
export const RCLeftDays = styled.div`
  display: none;
  font-family: 'Robo-BlackItalic';
  width: 100%;
  font-size: 60px;
  height: 101px;
  margin-top: -20px;
  text-align: center;
  color: #ffffff;
  p {
    font-size: 16px;
    margin: -10px 0 0 0;
    font-family: 'Roboto-Regular';
    letter-spacing: 2px;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const RCOrganization = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 8.05vh;
  left: 18.65vw;
  color: #ffffff;
  font-size: 18px;
  p {
    margin: unset;
  }
  .banner-content {
    margin-left: -45px;
    .title {
      margin-left: 55px;
      font-size: 20px;
      letter-spacing: 7px;
      margin-bottom: 27px;
    }
    img {
      width: 644px;
      height: 294px;
    }
    .slogon {
      position: relative;
    }
    .date {
      position: absolute;
      left: 195px;
      bottom: 56px;
      font-size: 20px;
      letter-spacing: 3.1px;
    }
  }
  ${Media.lessThan(Media.small)} {
    left: unset;
    bottom: 0;
    padding-left: 4.4vw;
    font-size: 2.66vw;
    .banner-content {
      margin-left: -4.4vw;
      .title {
        margin-left: 6.93vw;
        font-size: 1.5vh;
        letter-spacing: 7px;
        margin-bottom: 2.1vh;
      }
      img {
        width: 85.86vw;
        height: 39.2vw;
      }
      .slogon {
        position: relative;
      }
      .date {
        position: absolute;
        left: 24.53vw;
        bottom: 7.46vw;
        font-size: 1.5vh;
        letter-spacing: 3.1px;
      }
    }
  }
`
export const Organizer = styled.div`
  display: flex;
  span {
    width: fit-content;
  }
  img {
    margin-left: 1.66vw;
    &.rayvision {
      height: 19px;
      margin-left: 1.04vw;
    }
    &.guian {
      height: 22px;
    }
    &.guiyang {
      height: 23px;
    }
  }
  ${Media.lessThan(Media.small)} {
    span {
      min-width: max-content;
    }
    img {
      margin-left: 4.13vw;
      margin-bottom: 2.39vh;
      &.rayvision {
        height: 1.87vh;
        margin-left: 4.26vw;
        margin-bottom: ;
      }
      &.guian {
        height: 2.09vh;
      }
      &.guiyang {
        height: 2.17vh;
      }
    }
  }
`
export const CoOrganizer = styled.div`
  margin-top: 1.85vh;
  img {
    margin-left: 1.3vw;
    &.renderbus {
      height: 24px;
      margin-left: 0.99vw;
    }
    &.qingjiao {
      height: 25px;
    }
    &.three-cat {
      height: 24px;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 0;
    img {
      margin-left: 2.66vw;
      &.renderbus {
        height: 2.09vh;
        margin-left: 4.26vw;
      }
      &.qingjiao {
        height: 2.09vh;
      }
      &.three-cat {
        height: 2.09vh;
      }
    }
  }
`
export const Sponsor = styled.div`
  margin-top: 2.96vh;
  display: flex;
  align-items: center;
  span {
    min-width: max-content;
  }
  img {
    margin-left: 1.3vw;
    &.yun {
      height: 22px;
    }
    &.reallusion {
      height: 20px;
      margin-left: 0.93vw;
    }
    &.eco-plants {
      height: 19px;
    }
    &.d5 {
      height: 24px;
    }
    &.keni {
      height: 24px;
    }
    &.choos {
      height: 30px;
    }
    &.style-3d {
      height: 24px;
    }
    &.fusion {
      height: 18px;
    }
    &.sugon {
      height: 29px;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 2.54vh;
    align-items: flex-start;
    div {
      margin-top: -2px;
      margin-left: 4.26vw;
    }
    img {
      margin-left: 4.13vw;
      &.yun {
        height: 1.94vh;
        margin-left: 4.26vw;
        margin-right: 4.13vw;
        margin-top: 1.64vh;
      }
      &.reallusion {
        height: 1.87vh;
        margin: unset;
      }
      &.eco-plants {
        height: 1.79vh;
      }
      &.d5 {
        height: 1.64vh;
        margin-top: 1.64vh;
      }
      &.keni {
        height: 2.22vh;
        margin-top: 1.79vh;
        margin-left: 0;
      }
      &.choos {
        height: 1.64vh;
        margin-top: 1.64vh;
      }
      &.style-3d {
        height: 2.17vh;
        margin-top: 1.57vh;
      }
      &.fusion {
        height: 1.72vh;
        margin-top: 1.79vh;
        margin-left: 0;
        margin-right: 4.13vw;
      }
      &.sugon {
        height: 2.17vh;
        margin-top: 1.57vh;
        margin-left: 0;
      }
    }
  }
  ${Media.greaterThan(Media.small)} {
    br {
      display: none;
    }
  }
`
export const SupportUnits = styled.div`
  margin-top: 2.12vh;
  > div {
    margin-top: 1.38vh;
    &.second {
      margin-top: 1.85vh;
    }
    span {
      margin-right: 21px;
      font-size: 14px;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 2vh;
    .hideInPC {
      display: flex;
      > div {
        display: flex;
        flex-direction: column;
        span {
          min-width: max-content;
          font-size: 2.4vw;
          line-height: 5vw;
        }
      }
    }
  }
`
export const RCFormItem = styled.div`
  line-height: 31px;
  margin-bottom: ${p => `${p.bottom}px`};
  ${Media.lessThan(Media.small)} {
    margin-bottom: unset;
    line-height: 4.13vw;
    &.agreement {
      line-height: unset;
      margin-top: 6.26vw;
    }
    &.organization {
      margin-top: 6.26vw;
    }
    &.school {
    }
    &.major {
    }
    &.position {
      margin-top: 3.99vw;
    }
    &.business {
      margin-top: 3.99vw;
    }
    &.info-from {
      margin-top: 3.59vw;
      line-height: 31px;
    }
    &.student-card {
      margin-top: 3.59vw;
      ${RCLabel} {
        margin: 3.59vw 0 0 3vw;
      }
      ${StudentCardContainer} {
        margin-left: 0;
        ${RCUploadContainer} {
          width: 71vw;
          margin-left: -8vw;
          transform: scale(0.7);
        }
      }
    }
  }
`
export const RCDialogContainer = styled.div`
  position: fixed;
  display: ${p => (p.isActive ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
  .content {
    display: flex;
    width: 601px;
    height: 261px;
    padding: 61px 0 41px 54px;
    border: 1px solid;
    border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
    background: linear-gradient(0deg, #425fcb 0%, rgba(40, 60, 120, 0.1) 100%);
    img {
      width: 120px;
      height: 120px;
    }
    .text {
      margin-left: 45px;
      display: flex;
      flex-direction: column;
      p {
        margin: 0 0 21px 0;
        color: #ffffff;
        font-size: 30px;
        line-height: 30px;
        font-weight: bold;
      }
      span {
        color: #dddddd;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 10px;
      }
      button {
        width: 160px;
        height: 40px;
        border: unset;
        cursor: pointer;
        background: linear-gradient(90deg, #00fdb0 0%, #f7fc2a 100%);
        border-radius: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #050706;
        &:hover {
          background: linear-gradient(0deg, #00fdb0 0%, #f7fc2a 100%);
        }
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    .content {
      display: flex;
      width: max-content;
      justify-content: center;
      height: max-content;
      padding: 5vw;
      border: 1px solid;
      border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
      background: linear-gradient(0deg, #425fcb 0%, rgba(40, 60, 120, 0.1) 100%);
      img {
        width: 16vw;
        height: 16vw;
      }
      .text {
        margin-left: 5vw;
        display: flex;
        flex-direction: column;
        p {
          margin: 0 0 21px 0;
          color: #ffffff;
          font-size: 5vw;
          line-height: 5vw;
          font-weight: bold;
        }
        span {
          font-size: 2.666vw;
          line-height: 2.66vw;
          margin-bottom: 2vw;
        }
        button {
          width: 21.33vw;
          height: 5.33vw;
          font-size: 3vw;
        }
      }
    }
  }
`
