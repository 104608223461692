export const tabMap = new Map([
  ['champion', '冠军'],
  ['secondPlace', '亚军'],
  ['thirdPlace', '季军'],
  ['major', '专业组'],
  ['student', '学生组'],
])

export const professions = {
  champion: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/57790c43da072c328c2a91ee60b4de528f06ce07.mp4',
      name: '诅咒海盗',
      author: '彼尔德',
      interview: {
        title: '3ds Max 和 Maya 巨佬！瑞云渲染大赛专业组冠亚军彼尔德、Shuiguo专访来了！',
        link: 'https://www.renderbus.com/news/post-id-1220/',
      },
    },
  ],
  secondPlace: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/c28996f44712348fb97f324fd2bcbddbcb3f99fd.mp4',
      name: '千小喵的空中饭馆',
      author: 'Carson',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/9fa3beb9e06a85af7290fe0672b0df9bf90a27d4.mp4',
      name: '无人响应',
      author: '王商羽',
      interview: {
        title: '3ds Max 和 Maya 巨佬！瑞云渲染大赛专业组冠亚军彼尔德、Shuiguo专访来了！',
        link: 'https://www.renderbus.com/news/post-id-1220/',
      },
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/928b16fd969c647bd94d4e9c27e3c7def622e5cb.mp4',
      name: '运动时刻',
      author: 'lancelee',
    },
  ],
  thirdPlace: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/8766bdbee2768a6c57be36bc73042b0fa9125609.mp4',
      name: '末日生还者',
      author: '卢凯',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/1789d99ac1e8e3422b93cb662c0a40bb5eb1c11c.mp4',
      name: '月森',
      author: '蜗里奥',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/20f115ba6a0543353a7156d4ece7e3235875678f.mp4',
      name: '困在时间里的机器人',
      author: '张平',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/ff10ea613e7aef39a46e324ff419ca6218a982d6.mp4',
      name: '假定瞬间',
      author: '罗振鑫',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/12746a31934af730c6de5dcb0910157487114863.mp4',
      name: '海盗船长',
      author: '何荙铭',
      interview: {
        title: '从《道德经》和拳法中感悟3D动画的真谛？赛博道长的传奇CG之路丨瑞云专访',
        link: 'https://www.renderbus.com/news/post-id-1230/',
      },
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/b0ee08efbc9a42bf4fa77f9fe86f5073bf579b0e.mp4',
      name: 'Gaia盖亚',
      author: '王炜',
    },
  ],
}

export const students = {
  champion: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/b0eae4ffc7fc36585f893d81c73ecb8c983aeaf9.mp4',
      name: 'Adieu Atlas',
      author: '覃泽坤',
      interview: {
        title: '【云渲染专访】瑞云专访 | 刚学Blender一年就拿渲染大赛冠军？中传学子恐怖如斯！',
        link: 'https://www.renderbus.com/news/post-id-1223/',
      },
    },
  ],
  secondPlace: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/f20c42a7f3ee87727f087a1ab3c24a7c2d87ad51.mp4',
      name: '奇点舱',
      author: '蔡龙腾',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/712d3a9ab35684325b117cab636de2dcb7ac8aaf.mp4',
      name: '紧急归位',
      author: '耿思源',
      interview: {
        title:
          '【云渲染专访】跨专业自学Blender夺得国内知名渲染大赛亚军？两位学生组大佬超干货专访来了！',
        link: 'https://www.renderbus.com/news/post-id-1222/',
      },
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/73e5022298a89af742dd0762675c2d6b56892531.mp4',
      name: '坠落',
      author: '袁博',
      interview: {
        title:
          '【云渲染专访】跨专业自学Blender夺得国内知名渲染大赛亚军？两位学生组大佬超干货专访来了！',
        link: 'https://www.renderbus.com/news/post-id-1222/',
      },
    },
  ],
  thirdPlace: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/c2eeb9a273bc54bd81b81ec62dc38d0f436edc8d.mp4',
      name: '曙光',
      author: '李嘉涛',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/03733a1ef589476f90e4a2e3c0d20e0e27773e70.mp4',
      name: '创造亚当',
      author: '胡雪涯',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/37601f3c06e5888fb17af3ba2d991a16f236ef66.mp4',
      name: '掠夺',
      author: '胡定葳',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/abffded88f8f1acb8670be532755b5727016a243.mp4',
      name: 'RUN!',
      author: '郑立',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/31dd185ed64f4813ddabbd59865d3c8f84897ade.mp4',
      name: '蒸汽机车',
      author: '杨睿',
    },
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/32268b7f58a65a35d4a5967fe45e2392ff6c2653.mp4',
      name: '醒狮',
      author: '项则衡',
    },
  ],
}

export const wanhuaSpecial = [
  {
    link:
      'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/949b3361e892459764ee0a586a38598a1beed9e3.mp4',
    name: '杀生丸',
    author: '谢泽阳、刘静羽',
  },
]

export const style3D = [
  {
    link:
      'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/b0eae4ffc7fc36585f893d81c73ecb8c983aeaf9.mp4',
    name: 'Adieu Atlas',
    author: '覃泽坤',
  },
]

export const mostPopular = {
  major: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/24dc9dfa4917bb2b413c598a6d9a16ab23273e2c.mp4',
      name: '战争爆发',
      author: '舟午',
    },
  ],
  student: [
    {
      link:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/works/a4cc3d747cc52d354aaa2d9be9a9c9e2d34e0c20.mp4',
      name: 'SCI-FI冰层营地',
      author: '王海权',
    },
  ],
}
