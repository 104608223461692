import styled, { css } from 'styled-components'
import { transition, Media } from '@renderbus/common/theme'

export const GoldBorderFromBottomToTop = css`
  border: 1px solid transparent;
  border-image: linear-gradient(
      180deg,
      rgba(134.00000721216202, 189.00000393390656, 255, 0),
      rgba(134.00000721216202, 189.00000393390656, 255, 1)
    )
    1 1;
  background: linear-gradient(180deg, #242476 0%, #252679 100%, #271f77 100%);
  border-top: none;
`

export const GoldBorderFromTopToBottom = css`
  border: 1px solid transparent;
  border-image: linear-gradient(
      180deg,
      rgba(134.28300082683563, 188.60564589500427, 255, 1),
      rgba(242.60699540376663, 243.69638174772263, 255, 0)
    )
    1 1;
  border-bottom: none;
`

export const GoldBorderFromLeftToRight = css`
  border: 1px solid transparent;
  border-image: linear-gradient(
      90deg,
      rgba(134.00000721216202, 189.00000393390656, 255, 1),
      rgba(0, 0, 0, 0)
    )
    1 1;
  background: linear-gradient(90deg, #505bd3 0%, rgba(81, 92, 212, 0) 100%);
  border-right: none;
`

export const ExibitionWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 662px;
  box-sizing: border-box;
  position: relative;
  margin-top: 130px;
  padding: 45px 0 0 0;
  ${GoldBorderFromBottomToTop};
  display: flex;
  ${Media.lessThan(Media.small)} {
    display: block;
    padding: 0;
    height: auto;
    margin-top: 100px;
  }
`

export const TabWrapper = styled.div`
  position: absolute;
  display: flex;
  column-gap: 16px;
  top: -90px;
  justify-content: center;
  left: 0;
  right: 0;
  ${Media.lessThan(Media.small)} {
    top: -50px;
  }
`

export const Tab = styled.p`
  width: 246px;
  height: 70px;
  font-size: 20px;
  font-weight: 350;
  color: #ffffff;
  background: linear-gradient(180deg, #4653c0 0%, rgba(65, 75, 180, 0.2) 100%);
  transition: ${transition('all')};
  cursor: pointer;
  text-align: center;
  line-height: 70px;
  ${({ isActive }) =>
    isActive &&
    css`
      height: 80px;
      background: linear-gradient(180deg, #4c57ce 0%, rgba(111, 123, 255, 0.2) 100%);
      ${GoldBorderFromTopToBottom}
      line-height: 80px;
      transform: translateY(-10px);
      font-size: 20px;
      font-weight: 700;
      color: #ffffff;
    `};
  ${Media.lessThan(Media.small)} {
    width: 77px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    ${({ isActive }) =>
      isActive &&
      css`
        height: 46px;
        line-height: 46px;
        font-weight: 700;
      `};
  }
`

export const MenusWrapper = styled.div`
  width: 246px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
`

export const MenuItem = styled.div`
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 350;
  cursor: pointer;
  transition: all 0.3s;
  ${({ isActive }) =>
    isActive &&
    css`
      ${GoldBorderFromLeftToRight};
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
    `};
  ${Media.lessThan(Media.small)} {
    width: auto;
    line-height: 46px;
    height: 46px;
    font-size: 2.66vw;
    ${({ isActive }) =>
      isActive &&
      css`
        background: none;
        border: none;
        color: #6295fd;
        font-weight: 350;
      `};
  }
`

export const ContentWrapper = styled.div`
  width: calc(100% - 246px);
  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
