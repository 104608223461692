import React, { useState, useEffect } from 'react'
import {
  CarouselWrapper,
  ContentWrapper,
  ArrowLeftButton,
  ArrowRightButton,
  BottomContentWrapper,
  BottomContent,
  Interview,
} from './atoms'

export function Carousel({ content }) {
  const [curIndex, setCurIndex] = useState(0)
  useEffect(() => {
    setCurIndex(0)
  }, [content])
  return (
    <CarouselWrapper>
      {content.map((c, i) => (
        <ContentWrapper key={i} curIndex={curIndex}>
          <video key={c.link} src={c.link} controls />
          <BottomContent>
            <BottomContentWrapper>
              <div>
                <p>作品：{c.name}</p>
                <p>作者：{c.author}</p>
              </div>
            </BottomContentWrapper>
            {c.interview && curIndex === i ? (
              <Interview>
                <span>专访文章：</span>
                <span className='link'>
                  <a href={c.interview.link} target='blank'>
                    {c.interview.title}
                  </a>
                </span>
              </Interview>
            ) : (
              <></>
            )}
          </BottomContent>
        </ContentWrapper>
      ))}
      {content.length > 1 && (
        <>
          <ArrowLeftButton
            onClick={() => curIndex > 0 && setCurIndex(c => c - 1)}
            disable={curIndex <= 0}
          />
          <ArrowRightButton
            onClick={() => curIndex < content.length - 1 && setCurIndex(c => c + 1)}
            disable={curIndex >= content.length - 1}
          />
        </>
      )}
    </CarouselWrapper>
  )
}
