import React, { useState } from 'react'
import {
  ExibitionWrapper,
  TabWrapper,
  Tab,
  MenusWrapper,
  ContentWrapper,
  MenuItem,
} from './exibition.atom'
import { tabMap } from './utils'
import { Carousel } from '../carousel/carousel'

function Exibition({ groups }) {
  const initKeys = Array.isArray(groups[0].group) ? [] : Object.keys(groups[0].group)
  const [headerTabs, setHeaderTabs] = useState(initKeys)
  const [activeTab, setActiveTab] = useState(initKeys[0])
  const [currentGroup, setCurrentGroup] = useState(groups[0])

  const handleLeftMenu = index => {
    setHeaderTabs(Array.isArray(groups[index].group) ? [] : Object.keys(groups[index].group))
    setActiveTab(Array.isArray(groups[index].group) ? '' : Object.keys(groups[index].group)[0])
    setCurrentGroup(groups[index])
  }

  return (
    <ExibitionWrapper>
      <TabWrapper>
        {headerTabs.map(key => (
          <Tab key={key} isActive={activeTab === key} onClick={() => setActiveTab(key)}>
            {tabMap.get(key)}
          </Tab>
        ))}
      </TabWrapper>
      <MenusWrapper>
        {groups.map((g, i) => (
          <MenuItem
            key={g.name}
            isActive={currentGroup.name === g.name}
            onClick={() => handleLeftMenu(i)}
          >
            {g.name}
          </MenuItem>
        ))}
      </MenusWrapper>
      <ContentWrapper>
        <Carousel
          content={
            Array.isArray(currentGroup.group) ? currentGroup.group : currentGroup.group[activeTab]
          }
        />
      </ContentWrapper>
    </ExibitionWrapper>
  )
}

export default Exibition
